import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserSession } from 'src/app/models/shared/user-session';
import { pluck, map } from 'rxjs/operators';
import { environment } from '../../../../src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserSessionService {
  readonly API = environment.config.endpoints.ADA;
  readonly BOK = environment.config.endpoints.BOK;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  userSession = {} as UserSession;
  private userSessionSource = new BehaviorSubject<UserSession>(
    this.userSession,
  );
  currentUser$ = this.userSessionSource.asObservable();

  constructor(private http: HttpClient) {}

  setCurrentUser(userSession: UserSession) {
    this.userSessionSource.next(userSession);
  }

  verifyUser(): Observable<any> {
    const ENDPOINT = `${this.BOK}/analyst/findUserInApp`;
    return this.http.get<Array<any>>(ENDPOINT, this.httpOptions).pipe(
      pluck('message'),
      map((response) => response),
    );
  }

  logout(): Observable<any> {
    const ENDPOINT = `${this.API}/logout`;
    return this.http.delete<any>(ENDPOINT, this.httpOptions);
  }
}
