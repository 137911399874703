import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// SDBC Imports
import { BcFloatMenuModule } from '@bancolombia/design-system-web/bc-float-menu';
import { BcFooterModule } from '@bancolombia/design-system-web/bc-footer';
import { BcHeaderModule } from '@bancolombia/design-system-web/bc-header';
import { BcIconModule } from '@bancolombia/design-system-web/bc-icon';
import { BcMenuModule } from '@bancolombia/design-system-web/bc-menu';
import { BcTableModule } from '@bancolombia/design-system-web/bc-table';
import { BcPictogramModule } from '@bancolombia/design-system-web/bc-pictogram';
// MSAL module
import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
// Components
import { InformativeSubCategoryComponent } from './components/informative-sub-category/informative-sub-category.component';
import { UserDashboardComponent } from './components/user-dashboard/user-dashboard.component';
import { MainFooterComponent } from './shared/components/main-footer/main-footer.component';
import { MainHeaderComponent } from './shared/components/main-header/main-header.component';
import { MainMenuComponent } from './shared/components/main-menu/main-menu.component';
// Services
import { BcInputSelectModule } from '@bancolombia/design-system-web/bc-input-select';
import { InformativeService } from './services/informative/informative.service';
import { HomeComponent } from './components/home/home.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { UserComponent } from './shared/components/user/user.component';
import { DevelopmentComponent } from './shared/components/development/development.component';
import { MainRedirectComponent } from './shared/components/main-redirect/main-redirect.component';

const isIE =
  window.navigator.userAgent.indexOf('MSIE') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [
    AppComponent,
    MainMenuComponent,
    MainFooterComponent,
    MainHeaderComponent,
    InformativeSubCategoryComponent,
    UserDashboardComponent,
    HomeComponent,
    PageNotFoundComponent,
    UserComponent,
    DevelopmentComponent,
    MainRedirectComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    // SDBC Icon module
    BcIconModule.forRoot({
      path: 'https://library-sdb.apps.bancolombia.com/bds/7.0.5',
    }),
    // SDBC Imports
    BcMenuModule,
    BcFooterModule,
    BcHeaderModule,
    BcFloatMenuModule,
    BcTableModule,
    BcPictogramModule,
    // MSAL module
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.azureConfig.clientId,
          authority: `${environment.azureConfig.loginMicrosoft}/${environment.azureConfig.tenantId}`,
          redirectUri: environment.azureConfig.redirectUri,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: isIE,
        },
      }),
      {
        interactionType: InteractionType.Redirect,
        authRequest: { scopes: [environment.azureConfig.appScope] },
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map(
          Object.entries(environment.azureConfig.sendTokenTo),
        ),
      },
    ),
    BcInputSelectModule,
  ],
  providers: [
    InformativeService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
