import { Injectable } from '@angular/core';
import { pluck, map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  Information,
  ProductTDM,
  TdmFindOrder,
  TdmOrder,
} from 'src/app/models/tdm/tdm';

@Injectable({
  providedIn: 'root',
})
export class TdmService {
  readonly API = environment.config.endpoints.SFM;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  information: Information = null;
  private informationDataSource = new BehaviorSubject<Information>(
    this.information,
  );
  currentInformationData$ = this.informationDataSource.asObservable();

  tdmOrder: TdmOrder = null;
  private tdmOrderDataSource = new BehaviorSubject<TdmOrder>(this.tdmOrder);
  currentTdmOrderData$ = this.tdmOrderDataSource.asObservable();

  constructor(private http: HttpClient) {}

  updateCurrentInformationData(information: Information) {
    this.informationDataSource.next(information);
  }

  updateCurrentTdmOrderData(tdmOrder: TdmOrder) {
    this.tdmOrderDataSource.next(tdmOrder);
  }

  getDocumentTypes(): Observable<any> {
    const ENDPOINT = `${this.API}/commonList/findAllDocumentType`;
    return this.http.get<Array<any>>(ENDPOINT, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response),
    );
  }

  getTestTypes(): Observable<any> {
    const ENDPOINT = `${this.API}/commonList/findAllTestType`;
    return this.http.get<Array<any>>(ENDPOINT, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response),
    );
  }

  findOrdersByFilter(tdmFindOrder: TdmFindOrder): Observable<any> {
    const ENDPOINT = `${this.API}/orders/findOrdersByFilters`;
    return this.http.post<any>(ENDPOINT, tdmFindOrder, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response),
    );
  }

  createOrder(tdmOrder: TdmOrder): Observable<any> {
    const ENDPOINT = `${this.API}/orders/create`;
    return this.http.post<any>(ENDPOINT, tdmOrder, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response),
    );
  }

  updateOrder(tdmOrder: TdmOrder): Observable<any> {
    const ENDPOINT = `${this.API}/orders/update`;
    return this.http
      .post<any>(ENDPOINT, tdmOrder, this.httpOptions)
      .pipe(map((response) => response));
  }

  createView(tdmView: any): Observable<any> {
    const ENDPOINT = `${this.API}/views/create`;
    return this.http.post<any>(ENDPOINT, tdmView, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response),
    );
  }

  updateView(tdmView: any): Observable<any> {
    const ENDPOINT = `${this.API}/views/update`;
    return this.http.post<any>(ENDPOINT, tdmView, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response),
    );
  }

  findViewsByFilter(tdmView: any): Observable<any> {
    const ENDPOINT = `${this.API}/views/findViewsByFilters`;
    return this.http.post<any>(ENDPOINT, tdmView, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response),
    );
  }

  createProduct(tdmProduct: any): Observable<any> {
    const ENDPOINT = `${this.API}/products/create`;
    return this.http.post<any>(ENDPOINT, tdmProduct, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response),
    );
  }

  findAllViews(): Observable<any> {
    const ENDPOINT = `${this.API}/views/findAll`;
    return this.http.get<Array<any>>(ENDPOINT, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response),
    );
  }

  updateProduct(tdmProduct: any): Observable<any> {
    const ENDPOINT = `${this.API}/products/update`;
    return this.http.post<any>(ENDPOINT, tdmProduct, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response),
    );
  }

  findProductByName(nameOfProduct: string): Observable<any> {
    const ENDPOINT = `${this.API}/products/findByName/${nameOfProduct}`;
    return this.http.get<Array<any>>(ENDPOINT, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response),
    );
  }

  findAllProducts(): Observable<any> {
    const ENDPOINT = `${this.API}/products/findAll`;
    return this.http.get<Array<any>>(ENDPOINT, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response),
    );
  }

  findAllValidProducts(): Observable<Array<ProductTDM>> {
    const ENDPOINT = `${this.API}/products/findAllValidProducts`;
    return this.http.get<any>(ENDPOINT, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response),
    );
  }

  findProductById(idProduct: number): Observable<any> {
    const ENDPOINT = `${this.API}/products/findById/${idProduct}`;
    return this.http.get<any>(ENDPOINT, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response),
    );
  }
}
