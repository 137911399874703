<div class="bc-container">
  <div class="container-text bc-margin-2">
    <h1>{{ category }}</h1>
  </div>
  <div class="bc-row bc-justify-content-center">
    <div class="bc-col-3" *ngFor="let sub of subCategories">
      <div
        bc-card
        class="bc-card-select bc-card bc-center"
        (click)="navigateToSubCategory(sub.url)"
      >
        <div class="bc-card-body container-text">
          <h4 class="bc-align-center">{{ sub.subCategory }}</h4>
        </div>
      </div>
    </div>
  </div>
</div>
