<bc-header
  [menuInteraction]="true"
  [fixed]="true"
  headerType="bc-header-wrapper-black"
>
  <bc-header-left
    [ariaLabelogo]="ariaLabelogo"
    [ariaLabelChannel]="ariaLabelChannelName"
    logoName="bancolombia-horizontal"
    channelName="Administrador de Ambientes"
  >
  </bc-header-left>
  <bc-header-list>
    <bc-header-icon
      role="button"
      aria-label="Notificaciones"
      icon="notification"
      [tooltipIcon]="configTooltip"
      route="#"
    ></bc-header-icon>
    <app-user></app-user>
    <bc-header-item
      *ngIf="!loginDisplay"
      else
      logout
      text="Ingresar"
      icon="login"
      route="#"
      class="cursor-pointer"
      (click)="loginRedirect()"
    >
    </bc-header-item>
    <ng-template #logout>
      <bc-header-item
        text="Salir"
        icon="logout"
        route="#"
        class="cursor-pointer"
        (click)="logoutRedirect()"
      >
      </bc-header-item>
    </ng-template>
  </bc-header-list>
</bc-header>
