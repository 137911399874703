import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import {
  Booking,
  BookingData,
  BookingRequest,
  InitiativeType,
} from 'src/app/models/booking/booking';
import { environment } from '../../../../src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  readonly API = environment.config.endpoints.BOK;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  // booking = {} as Booking;
  booking: Booking = null;
  private bookingSource = new BehaviorSubject<Booking>(this.booking);
  currentBooking$ = this.bookingSource.asObservable();

  bookingData: BookingData = null;
  private bookingDataSource = new BehaviorSubject<BookingData>(
    this.bookingData,
  );
  currentBookingData$ = this.bookingDataSource.asObservable();

  constructor(private http: HttpClient) {}

  createBooking(booking: Booking): Observable<any> {
    const ENDPOINT = `${this.API}/booking/create`;
    return this.http
      .post<any>(ENDPOINT, booking)
      .pipe(map((response) => response));
  }

  updateBooking(booking: Booking): Observable<Booking> {
    const ENDPOINT = `${this.API}/booking/update/${booking.bookingId || ''}`;
    return this.http.post<Booking>(ENDPOINT, booking);
  }

  findBookingByFilter(
    bokingRequest: BookingRequest,
  ): Observable<Array<Booking>> {
    const ENDPOINT = `${this.API}/booking/findBookingByFilter`;
    return this.http
      .post<Array<Booking>>(ENDPOINT, bokingRequest, this.httpOptions)
      .pipe(
        pluck('data'),
        map((response: Array<Booking>) => response),
      );
  }

  findByUserId(userId: string): Observable<Array<Booking>> {
    const ENDPOINT = `${this.API}/booking/findByUserId/${userId || ''}`;
    return this.http.get<Array<Booking>>(ENDPOINT, this.httpOptions).pipe(
      pluck('data'),
      map((response: Array<Booking>) => response),
    );
  }

  findBookingById(bokingId: any): Observable<Booking> {
    const ENDPOINT = `${this.API}/booking/findById/${bokingId || ''}`;
    return this.http.get<any>(ENDPOINT, this.httpOptions).pipe(
      pluck('data'),
      map((response: Booking) => {
        this.bookingSource.next(response);
        return response;
      }),
    );
  }

  getInitiativeTypes(): Observable<Array<InitiativeType>> {
    const ENDPOINT = `${this.API}/initiativeType/findAll`;
    return this.http
      .get<Array<InitiativeType>>(ENDPOINT, this.httpOptions)
      .pipe(
        pluck('data'),
        map((response: Array<InitiativeType>) => response),
      );
  }

  changeBookingData(booking: Booking) {
    this.bookingSource.next(booking);
  }
  updateBookingData(bookingData: BookingData) {
    this.bookingDataSource.next(bookingData);
  }
}
