<div class="bc-container">
  <div class="container-text bc-margin-2">
    <h1>¡Bienvenido! {{ userSession?.user }}</h1>
  </div>
  <div class="bc-row bc-justify-content-center">
    <div class="bc-col-12">
      <div bc-card class="bc-card-select bc-card">
        <!-- My data -->
        <div class="bc-row bc-justify-content-center">
          <div class="bc-col-4">
            <div bc-card class="bc-card-select bc-card">
              <div class="bc-card-body">
                <bc-table-container
                  class="bc-col-12 bc-w-100"
                  *ngIf="lastBookings.length > 0"
                  [dataTable]="lastBookings"
                >
                  <bc-table-content>
                    <table
                      bc-table
                      sort="false"
                      [dropdownHtml]="true"
                      aria-label="Table for Bookings"
                    >
                      <thead>
                        <tr>
                          <th scope="row" bc-cell>#</th>
                          <th scope="row" bc-cell>Iniciativa</th>
                          <th scope="row" bc-cell></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let booking of lastBookings">
                          <td bc-cell>{{ booking.bookingId }}</td>
                          <td bc-cell>
                            {{ booking.initiative.initiativeType.description }}
                          </td>
                          <td bc-cell>
                            <bc-icon-button
                              type="ghost"
                              color="white"
                              shadow="true"
                              customIcon="info"
                              title="Mas detalles"
                              (click)="goToDetailBooking(booking.bookingId)"
                            ></bc-icon-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </bc-table-content>
                </bc-table-container>
              </div>
            </div>
          </div>
          <div class="bc-col-4">
            <div bc-card class="bc-card-select bc-card">
              <div class="bc-card-body">
                <bc-table-container
                  class="bc-col-12 bc-w-100"
                  *ngIf="lastWindows.length > 0"
                  [dataTable]="lastWindows"
                >
                  <bc-table-content>
                    <table
                      bc-table
                      sort="false"
                      [dropdownHtml]="true"
                      aria-label="Table for windows"
                    >
                      <thead>
                        <tr>
                          <th scope="row" bc-cell>#</th>
                          <th scope="row" bc-cell>Tipo de ventana</th>
                          <th scope="row" bc-cell></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let window of lastWindows">
                          <td bc-cell>{{ window.windowId }}</td>
                          <td bc-cell>{{ window.windowType }}</td>
                          <td bc-cell>
                            <bc-icon-button
                              type="ghost"
                              color="white"
                              shadow="true"
                              customIcon="info"
                              title="Mas detalles"
                              (click)="goToDetailWindow(window.windowId)"
                            ></bc-icon-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </bc-table-content>
                </bc-table-container>
              </div>
            </div>
          </div>
          <div class="bc-col-4">
            <div bc-card class="bc-card-select bc-card">
              <div class="bc-card-body">
                <bc-table-container
                  class="bc-col-12 bc-w-100"
                  *ngIf="lastOrders.length > 0"
                  [dataTable]="lastOrders"
                >
                  <bc-table-content>
                    <table
                      bc-table
                      sort="false"
                      [dropdownHtml]="true"
                      aria-label="Table for Orders"
                    >
                      <thead>
                        <tr>
                          <th scope="row" bc-cell>#</th>
                          <th scope="row" bc-cell>Fecha de entrega</th>
                          <th scope="row" bc-cell></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let order of lastOrders">
                          <td bc-cell>{{ order.createdDate }}</td>
                          <td
                            bc-cell
                            *ngIf="order?.deliveryDate == 'PENDIENTE'"
                          >
                            {{ order.deliveryDate }}
                          </td>
                          <td
                            bc-cell
                            *ngIf="order?.deliveryDate != 'PENDIENTE'"
                          >
                            {{ order.deliveryDate | date: "dd/MM/yyyy" }}
                          </td>
                          <td bc-cell>
                            <bc-icon-button
                              type="ghost"
                              color="white"
                              shadow="true"
                              customIcon="info"
                              title="Mas detalles"
                              (click)="goToDetailOrder(order.createdDate)"
                            ></bc-icon-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </bc-table-content>
                </bc-table-container>
              </div>
            </div>
          </div>
        </div>
        <!-- End of my data -->
      </div>
    </div>
  </div>
</div>
