import { InformativeService } from '../../services/informative/informative.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-informative-sub-category',
  templateUrl: './informative-sub-category.component.html',
  styleUrls: ['./informative-sub-category.component.sass'],
})
export class InformativeSubCategoryComponent implements OnInit {
  category: string;
  subCategory: string;
  subCategories: any;

  constructor(
    private route: ActivatedRoute,
    private informativeService: InformativeService,
  ) {}

  ngOnInit(): void {
    this.category = this.route.snapshot.queryParams.category;
    this.subCategory = this.route.snapshot.queryParams.subCategory;
    if (this.category === 'I D') {
      this.category = 'I+D';
    }
    this.getSubCategories(this.subCategory);
  }

  getSubCategories(category: string): void {
    this.informativeService.getSubCategories(category).subscribe((response) => {
      this.subCategories = response;
    });
  }

  navigateToSubCategory(subCategoryUrl: string): void {
    window.open(subCategoryUrl, '_blank');
  }
}
