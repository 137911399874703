import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  EventMessage,
  EventType,
  InteractionStatus,
} from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { UserSession } from 'src/app/models/shared/user-session';
import { UserSessionService } from '../../../services/common/user-session.service';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.sass'],
})
export class MainHeaderComponent implements OnInit, OnDestroy {
  ariaLabelogo = 'Bancolombia';
  ariaLabelChannelName = 'Administrador de Ambientes';
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  subtitle = 'header';
  formGroup: UntypedFormGroup;

  configTooltip = {
    isTooltip: true,
    textTooltip: 'Notificaciones',
    positionTooltip: 'bottom',
  };

  userSession = {} as UserSession;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private userSessionService: UserSessionService,
  ) {}

  ngOnInit(): void {
    this.initializeBroadcast();
    this.builForm();
  }

  listeningChanges() {
    this.formGroup.get('user').valueChanges.subscribe((value) => {
      this.setCurrentUser(value);
    });
  }

  initializeBroadcast() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS,
        ),
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None,
        ),
        takeUntil(this._destroying$),
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();
    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
    const currentUser = {
      user: activeAccount?.idTokenClaims?.name,
      email: activeAccount?.idTokenClaims?.preferred_username,
      roles: activeAccount?.idTokenClaims?.roles,
      payload: activeAccount?.idTokenClaims,
    } as UserSession;
    this.setCurrentUser(currentUser);
    this.formGroup.get('user').setValue(currentUser);
    this.verifyUser();
  }

  builForm(): void {
    this.formGroup = this.fb.group({
      user: new UntypedFormControl(''),
    });
  }

  getCurrentUser() {
    this.userSessionService.currentUser$.subscribe((currentUser) => {
      this.userSession = currentUser;
    });
  }

  verifyUser() {
    this.userSessionService.verifyUser().subscribe((response) => {
      this.getCurrentUser();
    });
  }

  setCurrentUser(userSession: UserSession) {
    this.userSessionService.setCurrentUser(userSession);
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  loginRedirect() {
    this.authService.loginRedirect();
  }

  logoutRedirect() {
    this.userSessionService.logout().subscribe(() => {});
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
