import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import {
  RequestWindow,
  RequestedWindow,
  WindowFeedback,
  WindowFilter,
  WindowResponse,
} from 'src/app/models/window/window';
import { environment } from '../../../environments/environment';
import { NotificationType } from './../../../../src/app/models/admin/notifications';
import { WindowData, WindowType } from './../../models/window/window';

@Injectable({
  providedIn: 'root',
})
export class WindowService {
  readonly API = environment.config.endpoints.BOK;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  windowData: WindowData = null;
  private windowDataSource = new BehaviorSubject<WindowData>(this.windowData);
  currentWindowData$ = this.windowDataSource.asObservable();

  windowType: WindowType = null;
  private windowTypeDataSource = new BehaviorSubject<WindowType>(
    this.windowType,
  );
  currentWindowTypeData$ = this.windowTypeDataSource.asObservable();

  constructor(private http: HttpClient) {}

  findWindowsByDate(dateTime: Date): Observable<any> {
    const ENDPOINT = `${
      this.API
    }/window/findWindowsByDate/${dateTime.toISOString()}`;
    return this.http.get<RequestedWindow>(ENDPOINT, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response),
    );
  }

  createWindow(requestWindow: RequestWindow): Observable<any> {
    const ENDPOINT = `${this.API}/window/create`;
    return this.http
      .post<any>(ENDPOINT, requestWindow)
      .pipe(map((response) => response));
  }

  findById(windowId: number): Observable<any> {
    const ENDPOINT = `${this.API}/window/findById/${windowId}`;
    return this.http.get<any>(ENDPOINT, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response),
    );
  }

  findWindowsByFilter(
    windowFilter: WindowFilter,
  ): Observable<Array<WindowResponse>> {
    const ENDPOINT = `${this.API}/window/listWindowFilter`;
    return this.http
      .post<any>(ENDPOINT, windowFilter)
      .pipe(map((response) => response.data));
  }

  updateWindow(requestWindow: RequestWindow, state: number): Observable<any> {
    const ENDPOINT = `${this.API}/window/update/${state}`;
    return this.http.post<any>(ENDPOINT, requestWindow).pipe(
      pluck('data'),
      map((response) => response),
    );
  }

  sendFeedback(windowFeedback: WindowFeedback): Observable<WindowFeedback> {
    const ENDPOINT = `${this.API}/windowfeedback/create`;
    return this.http.post<WindowFeedback>(ENDPOINT, windowFeedback).pipe(
      pluck('data'),
      map((response) => response),
    );
  }

  findWindowsTypes(isEmergencyFlow: boolean): Observable<any> {
    const ENDPOINT = `${this.API}/windowType/findWindowType/${isEmergencyFlow}`;
    return this.http.get<Array<WindowType>>(ENDPOINT, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response),
    );
  }

  findAllUnavailability(): Observable<any> {
    const ENDPOINT = `${this.API}/notificationType/findAllUnavailability`;
    return this.http
      .get<Array<NotificationType>>(ENDPOINT, this.httpOptions)
      .pipe(
        pluck('data'),
        map((response) => response),
      );
  }

  findAllWindowsTypes(): Observable<any> {
    const ENDPOINT = `${this.API}/windowType/findAll`;
    return this.http.get<Array<WindowType>>(ENDPOINT, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response),
    );
  }

  findWindowsTypesById(windowTypeId: number): Observable<any> {
    const ENDPOINT = `${this.API}/windowType/findConfiguration/${windowTypeId}`;
    return this.http.get<WindowType>(ENDPOINT, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response),
    );
  }

  createWindowType(windowType: WindowType): Observable<any> {
    const ENDPOINT = `${this.API}/windowType/create`;
    return this.http.post<any>(ENDPOINT, windowType).pipe(
      pluck('data'),
      map((response) => response),
    );
  }

  updateWindowType(windowType: WindowType): Observable<any> {
    const ENDPOINT = `${this.API}/windowType/update`;
    return this.http.post<any>(ENDPOINT, windowType).pipe(
      pluck('data'),
      map((response) => response),
    );
  }

  findSpecialWindow(): Observable<any> {
    const ENDPOINT = `${this.API}/window/findSpecialWindows`;
    return this.http.get<any>(ENDPOINT, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response),
    );
  }

  updateCurrentWindowData(windowData: WindowData) {
    this.windowDataSource.next(windowData);
  }

  updateCurrentWindowTypeData(windowType: WindowType) {
    this.windowTypeDataSource.next(windowType);
  }
}
