import { map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-main-redirect',
  templateUrl: './main-redirect.component.html',
  styleUrls: ['./main-redirect.component.sass'],
})
export class MainRedirectComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        map((params) => {
          if (params.url) {
            console.log(params.url);
            window.open(params.url, '_blank');
            this.router.navigate(['/dashboard']);
          }
        }),
      )
      .subscribe();
  }
}
