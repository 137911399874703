import { Injectable } from '@angular/core';
import { pluck, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InformativeService {
  readonly API = environment.config.endpoints.SFM;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  getAllCategories(): Observable<any> {
    const ENDPOINT = `${this.API}/categories/findAll`;
    return this.http.get<Array<any>>(ENDPOINT, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response),
    );
  }

  getSubCategories(category: string): Observable<any> {
    const ENDPOINT = `${this.API}/subcategories/findbycategories?categories=${category}`;
    return this.http.get<Array<any>>(ENDPOINT, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response),
    );
  }
}
