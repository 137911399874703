import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { UserDashboardComponent } from './components/user-dashboard/user-dashboard.component';
import { InformativeSubCategoryComponent } from './components/informative-sub-category/informative-sub-category.component';
import { HomeComponent } from './components/home/home.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { MsalGuard } from '@azure/msal-angular';
import { MainRedirectComponent } from './shared/components/main-redirect/main-redirect.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
    canActivate: [MsalGuard],
  },
  {
    path: 'dashboard',
    component: UserDashboardComponent,
    pathMatch: 'full',
  },
  // Informative page
  {
    path: 'informative',
    component: InformativeSubCategoryComponent,
  },
  // Booking Module
  {
    path: 'booking',
    loadChildren: () =>
      import('./modules/booking/booking.module').then((m) => m.BookingModule),
  },
  // Window Module
  {
    path: 'window',
    loadChildren: () =>
      import('./modules/window/window.module').then((m) => m.WindowModule),
  },
  // TDM Module
  {
    path: 'tdm',
    loadChildren: () =>
      import('./modules/tdm/tdm.module').then((m) => m.TdmModule),
  },
  // Self Management Module
  {
    path: 'self-management',
    loadChildren: () =>
      import('./modules/self-management/self-management.module').then(
        (m) => m.SelfManagementModule,
      ),
  },
  // Incident Management Module
  {
    path: 'incident-management',
    loadChildren: () =>
      import('./modules/incident-management/incident-management.module').then(
        (m) => m.IncidentManagementModule,
      ),
  },
  // Admin Module
  {
    path: 'admin',
    loadChildren: () =>
      import('./modules/admin/admin.module').then((m) => m.AdminModule),
  },
  // Component Redirect to external page
  {
    path: 'redirect',
    component: MainRedirectComponent,
  },
  // 404 page
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
