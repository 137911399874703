import { Component } from '@angular/core';

@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.sass'],
})
export class MainFooterComponent {
  year: number = new Date().getFullYear();
}
