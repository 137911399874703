import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { UserSession } from 'src/app/models/shared/user-session';
import { UserSessionService } from './../../../../../src/app/services/common/user-session.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.sass'],
})
export class UserComponent implements OnInit {
  formGroup: UntypedFormGroup;
  userSession$: Observable<UserSession>;

  constructor(
    private fb: UntypedFormBuilder,
    private userSessionService: UserSessionService,
  ) {}

  ngOnInit(): void {
    this.getCurrentUser();
  }

  getCurrentUser() {
    this.userSession$ = this.userSessionService.currentUser$;
  }

  builForm(): void {
    this.formGroup = this.fb.group({
      user: new UntypedFormControl(''),
    });
  }
}
