import { TdmService } from './../../services/tdm/tdm.service';
import { BookingService } from './../../services/booking/booking.service';
import { Component, OnInit } from '@angular/core';
import { UserSession } from 'src/app/models/shared/user-session';
import { UserSessionService } from '../../services/common/user-session.service';
import { WindowService } from '../../services/window/window.service';
import { WindowFilter, WindowResponse } from 'src/app/models/window/window';
import { Router } from '@angular/router';

const splitArray: number = -5;

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.sass'],
})
export class UserDashboardComponent implements OnInit {
  userSession = {} as UserSession;
  lastWindows = [] as Array<WindowResponse>;
  lastOrders = [] as Array<any>;
  lastBookings = [] as Array<any>;

  constructor(
    private userSessionService: UserSessionService,
    private windowService: WindowService,
    private bookingService: BookingService,
    private tdmService: TdmService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.getCurrentUser();
  }

  getCurrentUser() {
    this.userSessionService.currentUser$.subscribe((currentUser) => {
      this.userSession = currentUser;
      this.getLastWindowsByUser();
    });
  }

  goToDetailWindow(windowId: number) {
    this.router.navigate(['window/detail'], {
      queryParams: { windowId: windowId },
    });
  }

  goToDetailOrder(orderId: number) {
    this.router.navigate(['tdm/detail-order'], {
      queryParams: { orderId: orderId },
    });
  }

  goToDetailBooking(bookingId: number) {
    this.router.navigate(['booking/detail'], {
      queryParams: { bookingId: bookingId },
    });
  }

  getLastWindowsByUser() {
    let windowFilter = {
      windowId: null,
      createBy: this.userSession?.email?.split('@')[0],
      startTime: null,
    } as WindowFilter;
    this.windowService
      .findWindowsByFilter(windowFilter)
      .subscribe((windows) => {
        this.lastWindows = windows.splice(splitArray).reverse();
      });
  }

  getLastOrdersByUser() {
    let orderFilter = {
      assigned: '',
      id: '',
      state: '',
      user: this.userSession.email.split('@')[0],
    } as any;

    this.tdmService.findOrdersByFilter(orderFilter).subscribe((orders) => {
      this.lastOrders = orders.splice(splitArray).reverse();
    });
  }

  getLastBookingsByUser() {
    let bookingFilter = {
      bookingId: '',
      codeInitiative: '',
      createdBy: this.userSession.email.split('@')[0],
      endDate: '',
      endTime: null,
      startDate: '',
      startTime: null,
    } as any;
    this.bookingService
      .findBookingByFilter(bookingFilter)
      .subscribe((bookings) => {
        this.lastBookings = bookings.splice(splitArray).reverse();
      });
  }
}
