import { InformativeService } from './../../../services/informative/informative.service';
import { Categories } from './../../../models/informative/categories';
import { Component, OnInit } from '@angular/core';
import { UserSessionService } from '../../../services/common/user-session.service';
import { UserSession } from 'src/app/models/shared/user-session';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.sass'],
})
export class MainMenuComponent implements OnInit {
  categories: Array<Categories> = [];
  grafanaURL: string = 'https://grafana-pdti.apps.bancolombia.com';
  azureURL: string =
    'https://grupobancolombia.visualstudio.com/Vicepresidencia%20Servicios%20de%20Tecnolog%C3%ADa';

  teamsURL: string =
    'https://teams.microsoft.com/l/team/19%3a68BDr1Dsb1Vx5dux4fzkNJbntqxe24gFVxU9V7hoTiU1%40thread.tacv2';

  groupId: string = 'cc65c45d-ab9e-4142-a0ef-87213428b28f';

  viewsTdmURL: string =
    '_wiki/wikis/Vicepresidencia%20Servicios%20de%20Tecnología.wiki/964/Vistas-TDM';

  userSession = {} as UserSession;
  userAdmin: boolean = false;
  userTdm: boolean = false;
  userActive: boolean = false;

  constructor(
    private informativeService: InformativeService,
    private userSessionService: UserSessionService,
  ) {}

  ngOnInit(): void {
    this.getAllCategories();
    this.getCurrentUser();
  }

  getAllCategories() {
    this.informativeService
      .getAllCategories()
      .subscribe((response: Array<Categories>) => {
        this.categories = response;
        this.categories.sort((a, b) => a.category.localeCompare(b.category));
      });
  }

  getCurrentUser() {
    this.userSessionService.currentUser$.subscribe((currentUser) => {
      this.userSession = currentUser;
      if (this.userSession.user != null) {
        this.userActive = true;
      }
      if (this.userSession.roles != null) {
        if (this.userSession.roles.includes('ROLE_ADMIN')) {
          this.userAdmin = true;
        }
        if (this.userSession.roles.includes('ANALISTA_TDM')) {
          this.userTdm = true;
        }
      }
    });
  }
}
